@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy"), url(./fonts/Gilroy-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy-Medium"),
      url(./fonts/Gilroy-Medium.ttf) format("truetype");
    font-style: medium;
    font-weight: 500;
  }

  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy-SemiBold"),
      url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
    font-style: semibold;
    font-weight: 600;
  }

  @font-face {
    font-family: "Eina02";
    src: local("Eina02"), url(./fonts/Eina02-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "Eina02";
    src: local("Eina02-SemiBold"),
      url(./fonts/Eina02-SemiBold.ttf) format("truetype");
    font-style: semibold;
    font-weight: 600;
  }

  @font-face {
    font-family: "Sacramento";
    src: local("Sacramento-Regular"),
      url(./fonts/Sacramento-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
  }

}

@layer components {
  .btn {
    @apply flex justify-center items-center px-8 py-2 rounded shadow font-title font-semibold;
  }

  .btn-primary {
    @apply bg-blue text-white;
  }

  .btn-secondary {
    @apply bg-white text-darkgray;
  }

  .btn-danger {
    @apply bg-red text-white;
  }

  .btn:disabled {
    @apply bg-gray text-gray !important;
  }

  .textfield {
    @apply border border-gray px-2 rounded;
  }

  .tooltip {
    @apply hidden group-hover:block absolute px-2 py-1 z-10 bg-black text-white text-xs whitespace-nowrap rounded;
  }
}

.btn-google {
  background-image: url("https://static.portant.co/btn-google-signin.png");
  background-size: cover;
  width: 191px;
  height: 46px;
}

.btn-google.light {
  background-image: url("https://static.portant.co/btn-google-signin-light.png");
}

.btn-google:hover {
  background-image: url("https://static.portant.co/btn-google-signin-pressed.png");
}

.btn-google.light:hover {
  background-image: url("https://static.portant.co/btn-google-signin-light-pressed.png");
}

.btn-google::after {
  /* preload */
  content: url("https://static.portant.co/btn-google-signin-pressed.png");
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -9999;
}

.btn-google.light::after {
  /* preload */
  content: url("https://static.portant.co/btn-google-signin-light-pressed.png");
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -9999;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}
